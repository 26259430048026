import Vue from "vue";
import VueRouter from "vue-router";
import PublicLayout from '@/views/layouts/PublicLayout';
import DashboardLayout from '@/views/layouts/DashboardLayout';

import { EventBus } from '@/events_bus.js';

// Pages

import Dashboard from '@/views/pages/Dashboard';
import Login from '@/views/pages/Login';
import ForgotPassword from "@/views/pages/ForgotPassword";
import ResetPassword from "@/views/pages/ResetPassword";
import QrCodes from '@/views/pages/QrCodes';
import CreateQr from '@/views/pages/CreateQr';
import EditQr from "@/views/pages/EditQr";
import Categories from "@/views/pages/Categories";
import CreateCategory from "@/views/pages/CreateCategory";
import EditCategory from '@/views/pages/EditCategory';



Vue.use(VueRouter);
const guardAuthService = async (to, from, next) => {
	try {
		let params = window.location.href.split('&');
		params = params.filter(p => {
			return p.includes('access_token=');
		})

		let token = localStorage.getItem('token');
		setTimeout(() => {

			if(token && token !== '') {
				next();
			} else {
				next({
					name: 'login.page'
				})
			}
		}, 20)
		if (token && token !== '') {
			next()
		} else {
			next({
				name: 'login.page',
			})
		}

	} catch (e) {
		next({
			name: 'login.page',
		})
	}
};

const routes = [
	{
		path: '/app',
		name: 'app.page',
		component: DashboardLayout,
		beforeEnter: (to, from, next) => {
			guardAuthService(to, from, next);
		},
		children: [
			{
				path: '',
				name: 'dashboard.page',
				component: Dashboard,
				beforeEnter: (to, from, next) => {
					EventBus.$emit('route-change');
					next();
				}
			},
			{
				path: '/links',
				name: 'qrcodes.page',
				component: QrCodes,
				beforeEnter: (to, from, next) => {
					EventBus.$emit('route-change');
					next();
				}
			},
			{
				path: '/links/create',
				name: 'create-qr.page',
				component: CreateQr,
			},
			{
				path: '/links/edit/:id',
				name: 'edit-qr.page',
				component: EditQr
			},
			{
				path: '/categories',
				name: 'categories.page',
				component: Categories,
				beforeEnter: (to, from, next) => {
					console.log('here')
					EventBus.$emit('route-change');
					next();
				}
			},
			{
				path: '/categories/create',
				name: 'create-category.page',
				component: CreateCategory
			},
			{
				path: '/categories/edit/:id',
				name: 'edit-category.page',
				component: EditCategory
			}
		]
	},
	{
		path: '/',
		component: PublicLayout,
		children: [
			{
				path: '',
				name: 'login.page',
				component: Login
			},
			{
				path: '/reset-password',
				name: 'reset-password.page',
				component: ResetPassword
			},
			{
				path: '/forgot-password',
				name: 'forgot-password.page',
				component: ForgotPassword
			}
		]
	},
	{
		path: '*',
		redirect: '/'
	}
];

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 }
	}
});

export default router;
