const filters = [
	{
		name: 'currency',
		execute: (value) => {
			let moneta = '';
			switch(value.trim().toLowerCase()) {
				case 'USD':
					moneta = '&dollar;';
					break;
				default:
					moneta = '&euro;';
					break;
					
			}
			return moneta;
		}
	}
]

export default filters;