<template>
	<main id="createcat" class="createcat page">
		<PageLoading v-if="loading" :isloading="loading" />
		<span class="createcat__title">
			Crea una nuova Categoria
		</span>
		<div class="createcat__form">
			<label for="nome" class="createcat__label">
				<span class="createcat__label__text">Nome</span>
				<input type="text" name="nome" id="nome" v-model="cat.name" />
			</label>
			<label for="nome" class="createcat__label half">
				<span class="createcat__label__text">Colore</span>
				<ColorPicker class="p-colorpicker-overlay" v-model="cat.color" />
			</label>
			<span for="nome" class="createcat__label half">
				<span class="createcat__label__text">Icona</span>
				<Dropdown v-model="cat.icon" :options="icons" optionLabel="icon" :filter="true" placeholder="Seleziona un'icona" :showClear="true">
					<template #value="slotProps">
						<div class="p-dropdown-icon-value" v-if="slotProps.value">
							<i :class="`pi pi-${slotProps.value.icon}`"></i>
							<span>{{slotProps.value.name}}</span>
						</div>
						<span v-else>
            				{{slotProps.placeholder}}
						</span>
					</template>
					<template #option="slotProps">
						<div class="p-dropdown-icon-option o-ratio u-1:1">
							<i :class="`pi pi-${slotProps.option.icon}`"></i>
						</div>
					</template>
				</Dropdown>
			</span>
			<div class="createcat__ctas" @click="cancel">
				<span class="createcat__btn btn noborder">
					Annulla
				</span>
				<span class="createcat__btn btn filled" @click="create">
					Crea
				</span>
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from "@/events_bus";
import ColorPicker from 'primevue/colorpicker';
import Dropdown from 'primevue/dropdown';
import PageLoading from "@/views/components/layout/PageLoading.vue";


export default {
	name: 'CreateCategory',
	components: {
		PageLoading,
		ColorPicker,
		Dropdown
	},
	data() {
		return {
			url: null,
			loading: false,
			cat: {
				name: null,
				color: null,
				icon: null,
			},
			icons: null,
		}
	},
	methods: {
		cancel() {
		
		},
		create() {
			this.loading = true;
			console.log('--->>> ', this.cat);
			if(this.cat.icon) {
				this.cat.icon = this.cat.icon.icon;
			}
			this.$service.cats.create(this.cat).then(res => {
				console.log('RES', res);
				this.loading = false;
				window.location.href = '#/categories';
			})
		},
		init() {
			this.icons = [];
			if(this.$store.state.icons) {
				this.icons = [...this.$store.state.icons];
			}
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.createcat {
	&__title {
		@extend  %text-33;
		display: block;
		width: 100%;
		font-weight: 700;
		color: $color-gun;
		margin-bottom: var(--supergutter);
	}
	&__form {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
	}
	&__label {
		display: block;
		width: 100%;
		margin-bottom: var(--gutter);
		&__text {
			@extend %text-12;
			display: block;
			font-weight: 600;
			text-transform: uppercase;
			margin-bottom: var(--thirdgutter);
		}
		&__info {
			display: block;
			width: 100%;
			@extend %text-12;
			color: rgba($color-gun, 0.7);
			margin-bottom: var(--thirdgutter);
		}
		input {
			display: flex;
			align-items: center;
			@include size(100%,var(--input));
			background: $color-isabelline;
			padding: 0 var(--thirdgutter);
			border-radius: var(--radius);
			border: 0;
		}
	}
	&__tags {
		@extend %flexCenterStart;
		width: 100%;
		margin-top: var(--thirdgutter);
		.tag {
			@extend %flexCenterStart;
			width: max-content;
			padding: 6px 10px;
			background: $color-isabelline;
			border-radius: var(--radius);
			margin-right: 5px;
			&__label {
				display: block;
				@extend %text-12;
				padding-right: 10px;
			}
			&__delete {
				display: block;
				@include size(10px,10px);
				position: relative;
				@extend %arrowClose;
				cursor: pointer;
				&:before,
				&:after {
					background: rgba($color-gun, 0.6);
					@include size(100%,1px);
					pointer-events: none;
				}
			}
		}
	}
	&__ctas {
		@extend %flexCenterEnd;
		width: 100%;
	}
	&__btn {
		cursor: pointer;
		&.filled {
			margin-left: var(--minigutter);
		}
	}
	@media (min-width: $from-large) {
		&__label {
			&.half {
				width: 48%;
			}
		}
		&__form {
			max-width: 600px;
		}
	}
}
</style>
