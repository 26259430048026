<template>
	<main id="forgotpsw" class="forgotpsw page">
		<PageLoading v-if="loading" :isLoading="loading" />
		<div class="content">
			<div class="forgotpsw__box">
				<div class="forgotpsw__title">
					<Logo />
				</div>
				<span class="forgotpsw__txt">
					Forgot Password
				</span>
				<template v-if="!linkSent">
					<form id="login-form" class="form">
						<label for="email" class="form__label">
							<span class="form__label__text">E-mail</span>
							<input type="email" name="email" id="email" v-model="email" autofocus />
						</label>
						<span v-if="genericError">
							Something went wrong! Try again in a few minutes!
						</span>
						<div class="form__ctas">
							<a href="#/" class="simple-link">Cancel</a>
							<span class="btn filled" @click="reset">Reset</span>
						</div>
					</form>
				</template>
				<template v-else>
					<span class="forgotpsw__text">
						We've sent you an email with a link to reset your password.
					</span>
					<a href="#/" class="forgotpsw__backhome btn filled">Home</a>
				</template>
			</div>
		</div>
	</main>
</template>

<script>
import PageLoading from "@/views/components/layout/PageLoading";
import Logo from "@/views/components/layout/Logo";

export default {
	name: 'ForgotPassword',
	components: {
		PageLoading,
		Logo
	},
	data() {
		return {
			email: null,
			genericError: false,
			loading: false,
			linkSent: false,
		}
	},
	methods: {
		reset() {
			this.loading = true;
			this.genericError = false;
			this.$service.auth.forgotPassword(this.email).then(res => {
				console.log('--->>>', res)
				this.loading = false;
				this.linkSent = true;
			}).catch(err => {
				console.error('ERR --->>>', err);
				this.loading = false;
			})
			setTimeout(() => {
				this.loading = false;
			}, 5000);
		}
	},
	mounted() {
		console.log(this.genericError)
	}
}
</script>
<style lang="scss">
.forgotpsw {
	position: relative;
	&__box {
		display: block;
		width: 90%;
		max-width: 450px;
		background: $color-white;
		border-radius: var(--radius);
		padding: var(--gutter) var(--gutter) var(--supergutter);
	}
	.content {
		@extend %flexCenterCenter;
		flex-direction: column;
		justify-items: center;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	&__title {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		svg {
			display: block;
			width: auto;
			height: 50px;
			margin: 0 auto;
		}
	}
	&__txt {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: var(--supergutter);
	}
	&__text {
		@extend %text-15;
		text-align: center;
	}
	&__backhome {
		display: block;
		margin: var(--supergutter) auto 0;
	}
	.form {
		display: block;
		width: 100%;
		&__ctas {
			justify-content: space-between;
			align-items: center;
		}
	}
	@media (min-width: $from-large) {
		.form {
			width: 400px;
		}
	}
}
</style>
