<template>
	<main id="categories" class="categories page">
		<div class="categories__head">
			<span class="categories__head__title">
				Categorie
			</span>
			<a href="#/categories/create" class="btn filled">
				<span class="btn__label">
					Crea Nuova
				</span>
				<span class="btn__icon">
					<i class="pi pi-plus"></i>
				</span>
			</a>
		</div>
		<div class="categories__content">
			<template v-if="cats && cats.length">
				<div class="categories__utils">
					<div class="categories__utils__search">
						<!--					<SearchQr />-->
					</div>
					<div class="categories__utils__filters">
						<span class="categories__icon" @click="sortList('name', titledir = !titledir)">
							<template v-if="titledir">
								<i class="pi pi-sort-alpha-down"></i>
							</template>
							<template v-else>
								<i class="pi pi-sort-alpha-up"></i>
							</template>
						</span>
						<span class="categories__icon" @click="viewMode">
							<template v-if="!grid">
								<i class="pi pi-th-large"></i>
							</template>
							<template v-else>
								<i class="pi pi-list"></i>
							</template>
						</span>
					</div>
				</div>
				<div v-if="grid" class="categories__list grid">
					<CatCard v-for="cat in cats" :key="cat.id" :cat="cat" />
				</div>
				<div v-if="!grid" class="categories__list list">
					<CatListItem v-for="cat in cats" :key="cat.id" :item="cat" />
				</div>
			</template>
			<div v-else class="categories__empty">
				<div class="categories__empty">
					<span class="categories__empty__label">
						Nessuna categoria presente!
					</span>
					<a href="#/categories/create" class="btn filled">
						<span class="btn__label">
							Crea Nuova
						</span>
						<span class="btn__icon">
							<i class="pi pi-plus"></i>
						</span>
					</a>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from "@/events_bus";
import CatCard from '@/views/components/cats/CatCard';
import CatListItem from "@/views/components/cats/CatListItem";

export default {
	name: 'Categories',
	components: {
		CatCard,
		CatListItem
	},
	data() {
		return {
			loading: false,
			cats: null,
			grid: true,
			titledir: true
		}
	},
	methods: {
		sortList(value, dir) {
			let direction = dir ? 'asc' : 'desc';
			this.$service.cats.get(`sort=${value},${direction}`).then(res => {
				this.cats = [...res.content];
			})
		},
		orderList() {
			this.asc = !this.asc
		},
		viewMode() {
			this.grid = !this.grid;
		},
		init() {
			this.$service.cats.get().then(res => {
				this.cats = [...res.content];
			})
		}
	},
	mounted() {
		this.init();
		console.log('MOUNTED')
		
		EventBus.$on('update-list', () => {
			console.log('UPDATE-EVENT')
			this.init();
		})
	}
}
</script>
<style lang="scss">
.categories {
	&__empty {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: var(--supergutter) 0;
		&__label {
			@extend %text-14;
			display: block;
			width: 100%;
			margin-bottom: var(--supergutter);
			text-align: center;
			text-transform: uppercase;
			opacity: 0.5;
		}
	}
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--supergutter);
		&__title {
			@extend %text-25;
			font-weight: 700;
		}
	}
	&__content {
		display: block;
		width: 100%;
	}
	&__utils {
		@extend %flexCenterBetween;
		width: 100%;
		margin-botom: var(--gutter);
		&__filters {
			@extend %flexCenterEnd;
			width: 50%;
		}
	}
	&__icon {
		cursor: pointer;
		margin-left: var(--thirdgutter);
	}
	&__list {
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-top: var(--gutter);
		.catcard {
			margin-bottom: var(--minigutter);
		}
		.list {
			&__head {
				display: flex;
				align-items: center;
				width: 100%;
				padding: var(--thirdgutter) var(--thirdgutter);
				border-bottom: 1px solid $color-silver;
				transition: 180ms linear;
				&__input { width: 15px; }
				&__media { width: var(--supergutter); }
				&__title {
					width: 30%;
					padding: 0 var(--gutter) 0 var(--minigutter);
				}
				&__info { width: 20%; }
				&__tags { width: 35%; }
				&__ctas { width: 80px; }
				&__value {
					@extend %flexCenterStart;
				}
				&__label {
					display: block;
					@extend %text-12;
					color: rgba($color-gun, 0.5);
					margin-right: var(--minigutter);
					text-transform: uppercase;
				}
				&__sort {
					@extend %flexCenterCenter;
					@include size(var(--minigutter),var(--minigutter));
					cursor: pointer;
					i {
						font-size: var(--minigutter) !important;
						pointer-events: none;
					}
				}
			}
		}
	}
	@media (min-width: $from-large) {
		&__list {
			.catcard {
				&:nth-child(3n + 2) {
					margin: 0 1% var(--minigutter);
				}
			}
		}
	}
}
</style>
