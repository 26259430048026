<script>
import { Bar } from 'vue-chartjs'
import dayjs from 'dayjs';
export default {
	name: "BarChart",
	extends: Bar,
	props: {
		data: {
			required: true,
		},
	},
	data() {
		return {};
	},
	mounted() {
		this.updateData();
	},
	methods: {
		updateData() {
			let d = [];
			let labels = [];
			this.data.forEach(dd => {
				d.push(dd.count);
				labels.push(dayjs(dd.date).format('DD MMM'))
			})
			this.renderChart(
				{
					datasets: [
						{
							data: d,
							backgroundColor: "#e63462",
							fill: true,
							maxBarThickness: 60
						},
					],
					labels: labels,
				},
				{
					responsive: true,
					maintainAspectRatio: false,
					legend: {
						display: false,
					},
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
								},
							},
						],
					},
				}
			);
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.updateData();
			},
		},
	},
};
</script>
