<template>
	<div class="searchqr">
		<span class="searchqr__icon">
			<i class="pi pi-search" />
		</span>
		<input type="text" name="search" id="search" class="searchqr__input" autocomplete="off" placeholder="Cerca..." v-model="search" @input="startSearch($event)" />
	</div>
</template>
<script>
import { EventBus } from "@/events_bus";

export default {
	name: 'SearchQr',
	data() {
		return {
			search: ''
		}
	},
	methods: {
		startSearch(event) {
			if(event.target.value.length > 2) {
				EventBus.$emit('search', event.target.value);
			}
		},
		init() {
			document.querySelector('#search').value = '';
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.searchqr {
	@extend %flexCenterStart;
	background: $color-isabelline;
	border-radius: var(--radius);
	@include size(300px,var(--input));
	padding: 0 var(--thirdgutter);
	&__icon {
		@extend %flexCenterCenter;
		@include size(25px,25px);
		i {
			font-size: 15px;
		}
	}
	&__input {
		display: block;
		@include size(calc(100% - 25px), 100%);
		background: none;
		border: 0;
		@extend %text-15;
	}
}
</style>
