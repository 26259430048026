<template>
	<main id="login" class="login page">
		<PageLoading v-if="loading" :isloading="loading" />
		<div class="content">
			<div class="login__box">
				<div class="login__title">
					<Logo />
				</div>
				<form id="login-form" class="form">
					<label for="email" class="form__label">
						<span class="form__label__text">E-mail</span>
						<input type="email" name="email" id="email" v-model="login.email" autofocus />
						<small v-if="errors.email">ERRORE</small>
					</label>
					<label for="password" class="form__label">
						<span class="form__label__text">Password</span>
						<span class="form__label__psw">
							<input :type="`${showpsw ? 'text' : 'password'}`" name="password" id="password" v-model="login.password" />
							<span class="form__label__psw__toggle" @click="showpsw = !showpsw">
								<template v-if="showpsw">
									<i class="pi pi-eye"></i>
								</template>
								<template v-else>
									<i class="pi pi-eye-slash"></i>
								</template>
							</span>
						</span>
						<small v-if="errors.password">ERRORE</small>
					</label>
					<div class="form__ctas">
						<a href="#/forgot-password" class="simple-link">Forgot password</a>
						<span class="btn filled" @click="auth">Log in</span>
					</div>
				</form>
			</div>
		</div>
	</main>
</template>
<script>
import { EventBus } from '@/events_bus.js';
import { gsap } from 'gsap';
import { CustomEase } from 'gsap/all';
import PageLoading from '@/views/components/layout/PageLoading';
import Logo from '@/views/components/layout/Logo';

gsap.registerPlugin(CustomEase);

export default {
	name: 'Login',
	components: {
		PageLoading,
		Logo
	},
	data() {
		return {
			editMode: 'mobile',
			loading: false,
			showpsw: false,
			login: {
				email: "",
				password: ""
			},
			errors: {
				email: false,
				password: false
			},
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		auth() {
			this.loading = true;
			this.$service.auth.signin(this.login).then(res => {
				localStorage.setItem('token', res.access_token);
				localStorage.setItem('me', JSON.stringify(res.user));
				this.loading = false;
				this.$router.push({ name: 'dashboard.page' })
			}).catch(err => {
				this.loading = false;
				if(err.messages && err.messages.errors && err.messages.errors.length) {
					err.messages.errors.forEach(er => {
						console.log(err.messages.errors)
						if(er.includes('Username')) {
							this.errors.email = true;
						}
						if(er.includes('Password')) {
							this.errors.password = true;
						}
					})
				}
				console.error('ERR --->>>', err);
			})
			setTimeout(() => {
				this.loading = false;
			}, 5000);
		},
		init() {
			
			localStorage.removeItem('token');
			localStorage.removeItem('me');
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		setTimeout(() => {
			this.init();
		}, 150)
	}
}
</script>
<style lang="scss">
.login {
	position: relative;
	&__box {
		display: block;
		width: 90%;
		max-width: 450px;
		background: $color-white;
		border-radius: var(--radius);
		padding: var(--gutter) var(--gutter) var(--supergutter);
	}
	.content {
		@extend %flexCenterCenter;
		flex-direction: column;
		justify-items: center;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	.form {
		display: block;
		width: 100%;
		&__label {
			&__psw {
				display: block;
				width: 100%;
				position: relative;
				input {
					position: relative;
					z-index: 0;
				}
				&__toggle {
					position: absolute;
					right: var(--thirdgutter);
					top: 50%;
					transform: translateY(-50%);
					z-index: 1;
					cursor: pointer;
				}
			}
		}
		&__ctas {
			justify-content: space-between;
			align-items: center;
		}
	}
	&__title {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: var(--supergutter);
		svg {
			display: block;
			width: auto;
			height: 50px;
			margin: 0 auto;
		}
	}
	@media (min-width: $from-large) {
		.form {
			max-width: 400px;
		}
	}
}
</style>
