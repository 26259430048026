<template>
	<div id="menu" class="menu" :class="{ 'active': toggleMenu }">
		<div class="menu__head">
			<span class="menu__head__logo">
				<Logo />
			</span>
			<button class="menu__head__btn" @click="handleMenu">
				<template v-if="editMode == 'desktop'">
					<i class="pi pi-angle-double-left" v-if="toggleMenu"></i>
					<i class="pi pi-angle-double-left" v-if="!toggleMenu"></i>
				</template>
				<template v-else>
					<span class="bar"></span><span class="bar"></span><span class="bar"></span>
				</template>
			</button>
		</div>
		<div class="menu__list">
			<router-link :to="{ name: 'dashboard.page' }" class="menu__link">
				<span class="menu__link__icon">
					<i class="pi pi-home"></i>
				</span>
				<span class="menu__link__label">
					Dashboard
				</span>
			</router-link>
			<router-link :to="{ name: 'qrcodes.page' }" class="menu__link">
				<span class="menu__link__icon">
					<i class="pi pi-link"></i>
				</span>
				<span class="menu__link__label">
					Links
				</span>
			</router-link>
			<router-link :to="{ name: 'categories.page' }" class="menu__link">
				<span class="menu__link__icon">
					<i class="pi pi-box"></i>
				</span>
				<span class="menu__link__label">
					Categorie
				</span>
			</router-link>
		</div>
		<div class="menu__foot">
			<a href="#/" class="menu__foot__btn">
				<span class="menu__foot__btn__icon">
					<i class="pi pi-sign-out"></i>
				</span>
				<span class="menu__foot__btn__label">
					Logout
				</span>
			</a>
		</div>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus';
import Logo from '@/views/components/layout/Logo';

export default {
	name: 'Menu',
	components: {
		Logo,
	},
	props: {
		editMode: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			toggleMenu: false,
		}
	},
	methods: {
		logout() {
			localStorage.removeItem('token');
			localStorage.removeItem('me');
			this.$router.push('/login');
		},
		changeRoute(dir) {
			if(this.editMode != 'desktop') {
				this.handleMenu();
			}
			this.$router.push(dir);
		},
		handleMenu() {
			this.toggleMenu = !this.toggleMenu;
			EventBus.$emit('toggle-menu', this.toggleMenu);
		},
		init() {
			console.log(this.$route)
		},
	},
	mounted() {
		this.init();
		
		EventBus.$on('route-change', () => {
			console.log('router-change')
			this.toggleMenu = false;
		})
	}
}
</script>
<style lang="scss">
.menu {
	position: absolute;
	top: var(--minigutter);
	left: var(--minigutter);
	display: block;
	background: linear-gradient(45deg, $color-violet, $color-ultraviolet);
	@include size(calc(100vw - var(--gutter)), var(--head));
	padding: var(--minigutter);
	overflow: hidden;
	border-radius: var(--radius);
	transition: 635ms var(--ease-inout-sine);
	z-index: 1000;
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--gutter);
		&__logo {
			@extend %flexCenterStart;
			width: max-content;
			color: $color-white;
			max-width: calc(100% - var(--gutter));
			transition: 435ms var(--ease-inout-sine);
			.logo {
				display: block;
				width: auto;
				height: 30px;
				svg {
					display: block;
					@include size(100%,100%);
				}
			}
		}
		&__btn {
			display: block;
			@include size(var(--input),var(--input));
			position: relative;
			transition: 435ms var(--ease-inout-sine);
			.bar {
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				background: $color-white;
				@include size(24px, 2px);
				transform: translate(-50%,-50%);
				transition: 435ms var(--ease-inout-sine);
				pointer-events: none;
				&:nth-child(1) {
					margin-top: -5px;
					margin-left: -1px;
				}
				&:nth-child(3) {
					margin-top: 5px;
					margin-left: -1px;
				}
			}
		}
	}
	&__list {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
	&__link {
		@extend %flexCenterStart;
		@include size(100%,var(--input));
		position: relative;
		text-decoration: none !important;
		margin-bottom: var(--minigutter);
		&:before, &:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			@include size(calc(100% + 10px),calc(100% + 10px));
			opacity: 0;
			transition: 180ms linear;
			border-radius: calc(var(--radius) * 3);
			z-index: 1;
			transform: translate(-50%,-50%);
		}
		&:before {
			background: $color-seasalt;
		}
		&:after {
			background: linear-gradient(45deg, $color-cerise, $color-bittersweet);
		}
		&__icon, &__label {
			position: relative;
			z-index: 2;
			pointer-events: none;
		}
		&__icon {
			@extend %flexCenterCenter;
			@include size(var(--input),var(--input));
			font-size: var(--input);
			color: $color-white;
			i {
				@extend %flexCenterCenter;
				@include size(100% !important, 100% !important);
				color: $color-white !important;
				font-size: calc(var(--input) * 0.7) !important;
			}
		}
		&__label {
			display: block;
			width: calc(100% - var(input));
			@extend %text-13;
			color: $color-white;
			font-weight: 700;
			padding-left: var(--thirdgutter);
			text-transform: uppercase;
		}
		&.router-link-active {
			&:after {
				opacity: 1;
			}
			.menu__link__label {
				color: $color-white !important;
			}
			i {
				color: $color-white !important;
			}
		}
	}
	&__foot {
		position: absolute;
		bottom: 0;
		left: 0;
		display: block;
		width: 100%;
		padding: var(--minigutter);
		transform: translateY(100%);
		transition: 300ms linear;
		&__btn {
			@extend %flexCenterStart;
			position: relative;
			width: max-content;
			text-decoration: none !important;
			transition: 180ms linear;
			cursor: pointer;
			&__icon, &__label {
				position: relative;
				z-index: 2;
				pointer-events: none;
				transition: 180ms linear;
			}
			&__icon {
				@extend %flexCenterCenter;
				@include size(var(--gutter),var(--gutter));
				i {
					@extend %flexCenterCenter;
					color: $color-white;
					transition: 180ms linear;
				}
			}
			&__label {
				@extend %text-13;
				display: block;
				width: calc(100% - var(input));
				font-weight: 700;
				color: $color-white;
				text-transform: uppercase;
			}
			&:hover {
				.menu__foot__btn {
					&__icon {
						i {
							color: $color-cerise !important;
						}
					}
					&__label {
						color: $color-cerise;
					}
				}
			}
		}
	}
	&.active {
		height: calc(100svh - var(--gutter));
		box-shadow: 0 0 10px rgba($color-black, 0.1);
		overflow: visible;
		.menu__head {
			&__btn {
				@media (max-width: $to-large) {
					.bar {
						&:nth-child(1) {
							margin-top: 0;
							transform: translate(-50%,-50%) rotate(-45deg);
						}
						&:nth-child(2) {
							transform: translate(-50%,50%) scaleX(0);
						}
						&:nth-child(3) {
							margin-top: 0;
							transform: translate(-50%,-50%) rotate(45deg);
						}
					}
				}
			}
		}
		.menu__foot {
			transform: translateY(0);
		}
	}
	@media (min-width: $from-large) {
		background: transparent;
		width: var(--menu);
		height: calc(100svh - var(--gutter));
		&__head {
			&__logo {
				color: $color-gun;
			}
		}
		&__link {
			i {
				color: $color-gun !important;
			}
			&__label {
				color: $color-gun;
			}
			&:hover {
				&:before {
					opacity: 1;
				}
			}
		}
		&__foot {
			transform: translateY(0%);
			&__btn {
				&__icon {
					i {
						color: $color-gun;
					}
				}
				&__label {
					color: $color-gun;
				}
			}
		}
		&.active {
			width: calc(var(--gutter) + var(--input));
			box-shadow: none;
			.menu {
				&__head {
					&__logo {
						transform: translateY(-500%);
						width: 0px;
						overflow: hidden;
					}
					
					&__btn {
						transform: rotate(180deg);
					}
				}
				&__link__label{
					display: none;
				}
				&__foot__btn__label {
					display: none;
				}
			}
		}
	}
}

</style>
