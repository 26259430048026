<template>
	<div v-if="item" class="listitem">
		<span class="listitem__type">
			<small v-if="typeIcon" class="listitem__type__icon">
				<i :class="`pi pi-${typeIcon}`"></i>
			</small>
		</span>
		<span v-if="item.name" class="listitem__title" v-html="item.name"></span>
		<span class="listitem__info">
			<template v-if="item.categories && item.categories.length">
				<span v-if="item.categories[0].color" class="listitem__info__color" :style="`background: #${item.categories[0].color};`"></span>
				<span v-if="item.categories[0].name" class="listitem__info__value" v-html="item.categories[0].name"></span>
			</template>
		</span>
		<span class="listitem__tags">
			<template v-if="item.tags && item.tags.length">
				<span class="listitem__tags__tag" v-for="(tag, tagI) in item.tags" :key="tagI" v-html="tag"></span>
			</template>
		</span>
		<span class="listitem__date">
			<small class="listitem__date__value" v-html="date"></small>
		</span>
		<span class="listitem__ctas">
			<span class="listitem__btn" @click="downloadQr">
				<i class="pi pi-download"></i>
			</span>
			<a :href="`#/links/edit/${this.item.id}`" class="listitem__btn">
				<i class="pi pi-pencil"></i>
			</a>
			<span class="listitem__btn" @click="deleteLink">
				<i class="pi pi-trash"></i>
			</span>
		</span>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus";

export default {
	name: 'ListItem',
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			selected: false,
			typeIcon: null,
			date: null,
		}
	},
	methods: {
		downloadQr() {
			EventBus.$emit('download-qr', this.item);
		},
		deleteLink() {
			EventBus.$emit('confirm-delete-qr', this.item);
		},
		init() {
			console.log(this.item)
			let dd = new Date(this.item.created_at);
			this.date = `${dd.getDate()}/${dd.getMonth()}/${dd.getFullYear()}`
			this.$store.state.types.forEach(type => {
				if(type.id == this.item.type) {
					this.typeIcon = type.icon;
				}
			})
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.listitem {
	display: flex;
	align-items: center;
	width: 100%;
	padding: var(--thirdgutter) var(--thirdgutter);
	border-bottom: 1px solid $color-silver;
	transition: 180ms linear;
	&__type {
		width: 10%;
		@extend %flexCenterCenter;
		&__icon {
			@include size(20px,20px);
			@extend %flexCenterCenter;
			border-radius: 100%;
			background: $color-isabelline;
			margin-right: 5px;
			color: $color-silver;
		}
	}
	&__title {
		width: 25%;
		font-weight: 700;
		padding: 0 var(--gutter) 0 var(--minigutter);
	}
	&__info {
		width: 20%;
		@extend %flexCenterStart;
		&__color {
			@include size(10px,10px);
			border-radius: 100%;
			margin-right: 5px;
		}
		&__value {
			@extend %text-12;
		}
	}
	&__tags {
		@extend %flexCenterStart;
		width: 30%;
		&__tag {
			@extend %flexCenterStart;
			width: max-content;
			padding: 6px 10px;
			background: $color-isabelline;
			border-radius: var(--radius);
			margin-right: 5px;
			@extend %text-12;
		}
	}
	&__date {
		width: 12%;
	}
	&__ctas {
		width: 80px;
		@extend %flexCenterEnd;
	}
	&__btn {
		@extend %flexCenterCenter;
		@include size(var(--gutter),var(--gutter));
		border-radius: 100%;
		transition: 180ms linear;
		cursor: pointer;
		i {
			transition: 180ms linear;
			font-size: 15px !important;
		}
		&:hover {
			background: $color-cerise;
			i {
				color: $color-white !important;
			}
		}
	}
	&.selected {
		background: rgba($color-cerise, 0.1);
	}
	@media (hover: hover) {
		&:hover {
			background: rgba($color-black, 0.1);
		}
	}
	@media (max-width: $to-large) {
		&__input, &__info, &__tags {
			display: none;
		}
		&__title {
			width: 60%;
		}
	}
}
</style>
