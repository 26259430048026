<template>
	<div id="public" class="public page-container">
		<LoginBackground />
		<router-view />
	</div>
</template>

<script>
import LoginBackground from "@/views/components/login/LoginBackground.vue";

export default {
	name: 'PublicLayout',
	components: {LoginBackground},
	data() {
		return {
		
		}
	}
};
</script>

<style lang="scss">
// @import "~@/assets/scss/layouts/_public-layout";
</style>
