<template>
	<div class="totalopenings statpanel">
		<span class="statpanel__title">
			<span class="statpanel__title__icon">
				<i class="pi pi-box"></i>
			</span>
			<span class="statpanel__title__label">
				Total Categories
			</span>
		</span>
		<template v-if="totalCats">
			<ICountUp class="totalopenings__value" :endVal="totalCats" :options="{duration: 2, separator: ','}" />
		</template>
		<template v-else>
			<span class="totalopenings__value">
				-
			</span>
		</template>
	</div>
</template>
<script>
import ICountUp from 'vue-countup-v2';

export default {
	name: 'TotalCats',
	components: {
		ICountUp
	},
	data() {
		return {
			totalCats: null
		}
	},
	methods: {
		init() {
			this.$service.cats.get().then(res => {
				this.totalCats = res.total;
			});
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.totalopenings {
	@extend %statpanel;
	@extend %flexCenterBetween;
	display: flex;
	.statpanel__title {
		width: 40%;
		margin-bottom: 0;
	}
	.statpanel__value {
		border-radius: 100%;
		border: 2px dotted $color-silver;
	}
	&__value {
		@extend %text-33;
		display: block;
		width: 60%;
		font-weight: 700;
		color: $color-bittersweet;
		text-align: right;
	}
}
</style>
