<template>
	<main id="resetpsw" class="resetpsw page">
		<div class="content">
			<div class="resetpsw__box">
				<div class="resetpsw__title">
					<Logo />
				</div>
				<span class="resetpsw__txt">
					Reset Password
				</span>
				<form id="login-form" class="form">
					<label for="email" class="form__label">
						<span class="form__label__text">E-mail</span>
						<input type="email" name="email" id="email" v-model="form.email" autofocus />
					</label>
					<label for="password" class="form__label">
						<span class="form__label__text">Password</span>
						<span class="form__label__psw">
							<input :type="`${showpsw ? 'text' : 'password'}`" name="password" id="password" v-model="form.password" />
							<span class="form__label__psw__toggle" @click="showpsw = !showpsw">
								<template v-if="showpsw">
									<i class="pi pi-eye"></i>
								</template>
								<template v-else>
									<i class="pi pi-eye-slash"></i>
								</template>
							</span>
						</span>
					</label>
					<label for="confirm" class="form__label">
						<span class="form__label__text">Confirm Password</span>
						<span class="form__label__psw">
							<input :type="`${showconfpsw ? 'text' : 'password'}`" name="confirm" id="confirm" v-model="form.confirm" />
							<span class="form__label__psw__toggle" @click="showconfpsw = !showconfpsw">
								<template v-if="showconfpsw">
									<i class="pi pi-eye"></i>
								</template>
								<template v-else>
									<i class="pi pi-eye-slash"></i>
								</template>
							</span>
						</span>
					</label>
					<span v-if="genericError">
						Something went wrong! Try again in a few minutes!
					</span>
					<div class="form__ctas">
						<a href="#/" class="simple-link">Cancel</a>
						<span class="btn filled" @click="reset">Reset</span>
					</div>
				</form>
			</div>
		</div>
	</main>
</template>

<script>
import Logo from '@/views/components/layout/Logo';

export default {
	name: 'ResetPassword',
	components: {
		Logo
	},
	data() {
		return {
			genericError: false,
			loading: false,
			showpsw: false,
			showconfpsw: false,
			form: {
				email: null,
				password: null,
				confirm: null
			}
		}
	},
	methods: {
		reset() {
			this.genericError = false;
			this.loading = true;
			this.form.token = this.$route.query.token
			this.$service.auth.resetPassword(this.form).then(res => {
				console.log('--->>>', res)
				this.loading = false;
				this.$route.push({ name: 'login.page' })
			})
			setTimeout(() => {
				this.loading = false;
				this.genericError = true;
			}, 5000)
		}
	},
	mounted() {
		console.log(this.genericError)
	}
}
</script>
<style lang="scss">
.resetpsw {
	position: relative;
	&__box {
		display: block;
		width: 90%;
		max-width: 450px;
		background: $color-white;
		border-radius: var(--radius);
		padding: var(--gutter) var(--gutter) var(--supergutter);
	}
	.content {
		@extend %flexCenterCenter;
		flex-direction: column;
		justify-items: center;
		height: 100%;
		position: relative;
		z-index: 1;
	}
	&__title {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: var(--gutter);
		svg {
			display: block;
			width: auto;
			height: 50px;
			margin: 0 auto;
		}
	}
	&__txt {
		display: block;
		width: 100%;
		text-align: center;
		margin-bottom: var(--supergutter);
	
	}
	.form {
		display: block;
		width: 100%;
		&__label {
			&__psw {
				display: block;
				width: 100%;
				position: relative;
				input {
					position: relative;
					z-index: 0;
				}
				&__toggle {
					position: absolute;
					right: var(--thirdgutter);
					top: 50%;
					transform: translateY(-50%);
					z-index: 1;
					cursor: pointer;
				}
			}
		}
		&__ctas {
			justify-content: space-between;
			align-items: center;
		}
	}
	@media (min-width: $from-large) {
		.form {
			width: 400px;
		}
	}
}
</style>
