<template>
	<div v-if="item" class="catlistitem">
		<label :for="item.id" class="catlistitem__input">
			<!--			<input type="checkbox" :name="item.id" :id="item.id" class="catlistitem__input__input" />-->
			<!--			<span class="catlistitem__input__fake"></span>-->
		</label>
		<figure class="catlistitem__media">
			<i :class="`pi pi-${item.icon}`" :style="`color: #${item.color};`"></i>
		</figure>
		<span v-if="item.name" class="catlistitem__title">
			<span class="catlistitem__title__color" :style="`background: #${item.color};`"></span>
			<span class="catlistitem__title__value" v-html="item.name"></span>
		</span>
		<span class="catlistitem__info">
		
		</span>
		<span class="catlistitem__tags">
			<template v-if="item.tags && item.tags.length">
				<span class="catlistitem__tags__tag" v-for="(tag, tagI) in item.tags" :key="tagI" v-html="tag"></span>
			</template>
		</span>
		<span class="catlistitem__ctas">
			<a :href="`#/categories/edit/${this.item.id}`" class="catlistitem__btn">
				<i class="pi pi-pencil"></i>
			</a>
			<span class="catlistitem__btn" @click="deleteCat">
				<i class="pi pi-trash"></i>
			</span>
		</span>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus";

export default {
	name: 'CatListItem',
	props: {
		item: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			selected: false,
		}
	},
	methods: {
		deleteCat() {
			EventBus.$emit('confirm-delete-category', this.item);
		},
		init() {
			console.log(this.item)
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.catlistitem {
	display: flex;
	align-items: center;
	width: 100%;
	padding: var(--thirdgutter) var(--thirdgutter);
	border-bottom: 1px solid $color-silver;
	transition: 180ms linear;
	&__input {
		display: flex;
		align-items: center;
		@include size(15px,15px);
		&__fake {
			display: block;
			@include size(100%,100%);
			border-radius: 100%;
			border: 1px solid $color-silver;
			position: relative;
			cursor: pointer;
			&:before {
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				display: block;
				background: $color-blue;
				@include size(60%,60%);
				border-radius: 100%;
				transform: translate(-50%,-50%) scale(1);
				transition: 180ms linear;
			}
		}
		&__input { display: none; }
		.catlistitem__input__input:checked ~ .catlistitem__input__fake {
			&:before {
				transform: translate(-50%,-50%) scale(1);
			}
		}
		.catlistitem__input__input:not(:checked) ~ .catlistitem__input__fake {
			&:before {
				transform: translate(-50%,-50%) scale(0);
			}
		}
	}
	&__media {
		@extend %flexCenterCenter;
		@include size(var(--supergutter),var(--supergutter));
		i {
			@include size(var(--gutter),var(--gutter));
			font-size: 20px !important;
		}
		&__image {
			display: block;
			@include size(100%,100%);
		}
	}
	&__title {
		@extend %flexCenterStart;
		width: 30%;
		padding: 0 var(--gutter) 0 var(--minigutter);
		&__color {
			display: block;
			@include size(10px,10px);
			border-radius: 100%;
			margin-right: 10px;
		}
		&__value {
			font-weight: 700;
		}
	}
	&__info {
		width: 20%;
		@extend %flexCenterStart;
		&__color {
			@include size(10px,10px);
			border-radius: 100%;
			margin-right: 5px;
		}
		&__value {
			@extend %text-12;
		}
	}
	&__tags {
		@extend %flexCenterStart;
		width: 35%;
		&__tag {
			@extend %flexCenterStart;
			width: max-content;
			padding: 6px 10px;
			background: $color-isabelline;
			border-radius: var(--radius);
			margin-right: 5px;
			@extend %text-12;
		}
	}
	&__ctas {
		width: 80px;
		@extend %flexCenterEnd;
	}
	&__btn {
		@extend %flexCenterCenter;
		@include size(var(--gutter),var(--gutter));
		border-radius: 100%;
		transition: 180ms linear;
		cursor: pointer;
		i {
			transition: 180ms linear;
			font-size: 15px !important;
		}
		&:hover {
			background: $color-cerise;
			i {
				color: $color-white !important;
			}
		}
	}
	&.selected {
		background: rgba($color-cerise, 0.1);
	}
	@media (hover: hover) {
		&:hover {
			background: rgba($color-black, 0.1);
		}
	}
	@media (max-width: $to-large) {
		&__input, &__info, &__tags {
			display: none;
		}
		&__title {
			width: 60%;
		}
	}
}
</style>
