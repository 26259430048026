<template>
	<main id="dashboard" class="dashboard page" ref="page">
		<div class="dashboard__head">
			<h1 class="dashboard__head__title">Dashboard</h1>
			<div class="dashboard__head__date">
				{{ today }}
			</div>
		</div>
		<TotalQr class="third" />
		<TotalCats class="third" />
		<TotalOpeningDashboard class="third" />
		<OpeningOverTimeDashboard class="full" />
		<CountriesDashboard class="half" />
		<ReferersDashboard class="half" />
	</main>
</template>
<script>
import dayjs from "dayjs";
import TotalQr from '@/views/components/stats/all/TotalQr';
import TotalCats from '@/views/components/stats/all/TotalCats';
import TotalOpeningDashboard from '@/views/components/stats/all/TotalOpeningDashboard';
import OpeningOverTimeDashboard from "@/views/components/stats/all/OpeningOverTimeDashboard";
import CountriesDashboard from "@/views/components/stats/all/CountriesDashboard";
import ReferersDashboard from '@/views/components/stats/all/ReferersDashboard';

export default {
	name: 'Dashboard',
	components: {
		TotalQr,
		TotalCats,
		OpeningOverTimeDashboard,
		TotalOpeningDashboard,
		CountriesDashboard,
		ReferersDashboard
	},
	data() {
		return {
			wWidth: 0,
			today: '',
		}
	},
	methods: {
		onResize() {
			this.wWidth = window.innerWidth;
			if(this.wWidth < 1200) {
				this.editMode = 'mobile'
			} else {
				this.editMode = 'desktop'
			}
		},
		init() {
			let date = new Date();
			this.today = `${date.getDate()} ${date.toLocaleString('it-IT', { month: 'long' })} ${date.getFullYear()}`;
		}
	},
	mounted() {
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});
		this.init();
	}
}
</script>
<style lang="scss">
.dashboard {
	@extend %flexStartBetween;
	display: flex !important;
	flex-wrap: wrap;
	&__head {
		@extend %flexCenterBetween;
		width: 100%;
		margin-bottom: var(--gutter);
		&__title {
			@extend %text-25;
			font-weight: 600;
		}
		&__date {
			@extend %text-12;
			text-transform: uppercase;
		}
	}
	.third, .half {
		width: 100%;
	}
	@media (min-width: $from-large) {
		.third {
			width: 33%;
		}
		.half {
			width: 49.5%;
		}
	}
}
</style>
