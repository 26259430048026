<template>
	<div class="catcard o-ratio u-16:6">
		<span class="catcard__top">
			<span class="catcard__title">
				{{ cat.name }}
			</span>
			<span class="carcard__icon">
				<i :class="`pi pi-${cat.icon}`" :style="`color: #${cat.color};`"></i>
			</span>
		</span>
			<span class="catcard__bottom">
			<span class="catcard__color" :style="`background: #${cat.color};`"></span>
			<span class="catcard__ctas">
				<span class="catcard__icon" @click="editCat">
					<i class="pi pi-pencil"></i>
				</span>
				<span class="catcard__icon" @click="deleteCat">
					<i class="pi pi-trash"></i>
				</span>
			</span>
		</span>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus";
export default {
	name: 'CatCard',
	props: {
		cat: {
			type: Object,
			required: true
		}
	},
	methods: {
		editCat() {
			this.$router.push({ name: 'edit-category.page', params: { id: this.cat.id } })
		},
		deleteCat() {
			EventBus.$emit('confirm-delete-category', this.cat);
		},
	}
}
</script>
<style lang="scss">
.catcard {
	display: block;
	background: rgba($color-isabelline, 0.5);
	width: 100%;
	border-radius: var(--radius);
	border: 1px solid $color-isabelline;
	box-shadow: 3px 3px 10px rgba($color-gun, 0.1);
	transition: 250ms linear;
	&__top,
	&__bottom {
		position: absolute;
		left: 0;
		@extend %flexCenterBetween;
		width: 100%;
		padding: var(--minigutter);
	}
	&__top { top: 0; }
	&__bottom { bottom: 0; }
	&__title {
		@extend %text-20;
		color: $color-gun;
		font-weight: 500;
	}
	&__color {
		display: block;
		@include size(10px,10px);
		border-radius: 100%;
	}
	&__ctas {
		@extend %flexCenterEnd;
		width: 50%;
		opacity: 0;
	}
	&__icon {
		@extend %flexCenterCenter;
		@include size(30px,30px);
		border-radius: 100%;
		cursor: pointer;
		padding: 10px;
		transition: 180ms linear;
		i {
			font-size: 15px !important;
		}
		&:hover {
			background: $color-cerise;
			i {
				color: $color-white;
			}
		}
	}
	@media (hover: hover) {
		&:hover {
			box-shadow: 0 0 20px rgba($color-gun, 0.2);
			.catcard__ctas {
				opacity: 1;
			}
		}
	}
	@media (min-width: $from-large) {
		width: 31%;
	}
}
</style>
