<template>
	<svg id="icons" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
		<g id="logo">
			<symbol id="logo--mwd" viewBox="0 0 50 50">
				<path d="M38.8 24.2c0-4.3-3.5-7-7.8-7h-.9l-5.8 15.5H31c4.3.1 7.8-4.2 7.8-8.5m4.1 0c0 6.6-5.3 12.6-11.9 12.6H18.7c-.3 0-.5-.3-.4-.5l8.6-23c.1-.2.2-.3.4-.3h4.3c6.3.4 11.3 4.9 11.3 11.2m-37 12.4c-.1.2-.2.3-.4.3H1.8c-.3 0-.5-.3-.4-.5l8.6-23c.1-.2.2-.3.4-.3h3.8c.3 0 .5.3.4.5l-8.7 23m8.1.3h-3.8c-.3 0-.5-.3-.4-.5l8.6-23c.1-.2.2-.3.4-.3h3.8c.3 0 .5.3.4.5l-8.6 23c-.1.2-.2.3-.4.3M43.5 31h4.7c.3 0 .6.3.6.6v4.7c0 .3-.3.6-.6.6h-4.7c-.3 0-.6-.3-.6-.6v-4.7c0-.4.3-.6.6-.6" />
			</symbol>
		</g>
		<g id="icon">
			<symbol id="icon--svg" viewBox="0 0 50 50">
				<path d="M44.7 26.5v-1.8h4.5v4.5c-.4.5-1 .8-1.8 1.1-.8.3-1.7.4-2.6.4-1.4 0-2.6-.4-3.5-1.3-.9-.9-1.3-2.1-1.4-3.7v-1c0-1.1.2-2 .6-2.9.4-.8.9-1.4 1.7-1.9.7-.4 1.5-.7 2.5-.7 1.4 0 2.4.3 3.2.9.8.6 1.2 1.6 1.3 2.8h-2.6c-.1-.6-.3-1.1-.6-1.3-.3-.3-.7-.4-1.3-.4-.7 0-1.2.3-1.6.9-.4.6-.5 1.4-.6 2.4v.7c0 1.1.2 1.9.6 2.5.4.6 1 .8 1.8.8.7 0 1.2-.2 1.6-.5v-1.7h-1.8zm-12.5 4.1-3.7-11.1h3l2.2 8.1 2.2-8.1h3l-3.7 11.1h-3zm-8.5-6.7c.8.2 1.4.5 1.9.8 1.2.7 1.8 1.6 1.8 2.9 0 1-.4 1.7-1.1 2.3-.7.6-1.7.8-3 .8-.9 0-1.7-.2-2.4-.5s-1.3-.8-1.6-1.3c-.4-.6-.6-1.2-.6-1.9h2.7c0 .6.2 1 .5 1.3.3.3.8.4 1.5.4.4 0 .8-.1 1-.3.3-.2.4-.5.4-.8 0-.4-.1-.7-.4-.9-.3-.2-.8-.4-1.5-.7-.7-.2-1.3-.5-1.7-.7-1.4-.7-2.2-1.7-2.2-2.9 0-.6.2-1.2.5-1.6.4-.5.9-.8 1.5-1.1.7-.3 1.4-.4 2.2-.4.8 0 1.5.1 2.1.4.6.3 1.1.7 1.5 1.2.3.5.5 1.1.5 1.8h-2.7c0-.5-.1-.8-.4-1-.3-.2-.6-.4-1.1-.4-.5 0-.9.1-1.1.3-.3.2-.4.5-.4.8 0 .3.2.5.5.8.3.3.9.5 1.6.7zM12 32H2c-1.1 0-2-.9-2-2v-1.9c0-.4.3-.8.8-.8s.8.3.8.8V30c0 .3.2.5.5.5h10c.3 0 .5-.2.5-.5v-1.9c0-.4.3-.8.8-.8.4 0 .8.3.8.8V30c-.2 1.1-1.1 2-2.2 2zm-4.5-3.9-.1.1s-.1 0-.1.1c-.2.1-.4.1-.6 0 0 0-.1 0-.1-.1 0 0-.1 0-.1-.1L2.8 25c-.3-.3-.4-.8-.1-1.1.3-.3.8-.4 1.1-.1l2.5 2.1v-7.1c-.1-.5.3-.8.7-.8.4 0 .8.3.8.8v7.1l2.5-2.1c.1-.1.3-.2.5-.2s.4.1.6.3c.3.3.2.8-.1 1.1l-3.8 3.1z" />
			</symbol>
			<symbol id="icon--png" viewBox="0 0 50 50">
				<path d="M45.5 26.5v-1.8H50v4.5c-.4.5-1 .8-1.8 1.1-.8.3-1.7.4-2.6.4-1.4 0-2.6-.4-3.5-1.3-.9-.9-1.3-2.1-1.4-3.7v-1c0-1.1.2-2 .6-2.9.4-.8.9-1.4 1.7-1.9.7-.4 1.5-.7 2.5-.7 1.4 0 2.4.3 3.2.9.8.6 1.2 1.6 1.3 2.8h-2.6c-.1-.6-.3-1.1-.6-1.3-.3-.3-.7-.4-1.3-.4-.7 0-1.2.3-1.6.9-.4.6-.5 1.4-.6 2.4v.7c0 1.1.2 1.9.6 2.5.4.6 1 .8 1.8.8.7 0 1.2-.2 1.6-.5v-1.7h-1.8zm-13.4-2.8v6.9h-2.7V19.5h2.7l3.9 6.9v-6.9h2.7v11.1H36l-3.9-6.9zm-8.9 3.1h-1.7v3.8h-2.7V19.5h4.4c.8 0 1.6.2 2.2.5.7.3 1.2.8 1.5 1.3.4.6.5 1.2.5 2 0 1.1-.4 1.9-1.2 2.6-.6.6-1.7.9-3 .9zm1.2-4.8c-.3-.3-.7-.5-1.1-.5h-1.8v3.2h1.7c.5 0 .9-.1 1.2-.4.3-.3.4-.6.4-1.1 0-.5-.2-.9-.4-1.2zM12 32H2c-1.1 0-2-.9-2-2v-1.9c0-.4.3-.8.8-.8s.8.3.8.8V30c0 .3.2.5.5.5h10c.3 0 .5-.2.5-.5v-1.9c0-.4.3-.8.8-.8.4 0 .8.3.8.8V30c-.2 1.1-1.1 2-2.2 2zm-4.5-3.9-.1.1s-.1 0-.1.1c-.2.1-.4.1-.6 0 0 0-.1 0-.1-.1 0 0-.1 0-.1-.1L2.8 25c-.3-.3-.4-.8-.1-1.1.3-.3.8-.4 1.1-.1l2.5 2.1v-7.1c-.1-.5.3-.8.7-.8.4 0 .8.3.8.8v7.1l2.5-2.1c.1-.1.3-.2.5-.2s.4.1.6.3c.3.3.2.8-.1 1.1l-3.8 3.1z" />
			</symbol>
			<symbol id="icon--jpg" viewBox="0 0 50 50">
				<path d="M43.9 26.5v-1.8h4.5v4.5c-.4.5-1 .8-1.8 1.1-.8.3-1.7.4-2.6.4-1.4 0-2.6-.4-3.5-1.3-.9-.9-1.3-2.1-1.4-3.7v-1c0-1.1.2-2 .6-2.9.4-.8.9-1.4 1.7-1.9.7-.4 1.5-.7 2.5-.7 1.4 0 2.4.3 3.2.9.8.6 1.2 1.6 1.3 2.8h-2.6c-.1-.6-.3-1.1-.6-1.3-.3-.3-.7-.4-1.3-.4-.7 0-1.2.3-1.6.9-.4.6-.5 1.4-.6 2.4v.7c0 1.1.2 1.9.6 2.5.4.6 1 .8 1.8.8.7 0 1.2-.2 1.6-.5v-1.7h-1.8zm-10.9.3h-1.7v3.8h-2.7V19.5H33c.8 0 1.6.2 2.2.5.7.3 1.2.8 1.5 1.3.4.6.5 1.2.5 2 0 1.1-.4 1.9-1.2 2.6-.6.6-1.6.9-3 .9zm1.2-4.8c-.3-.3-.7-.5-1.1-.5h-1.8v3.2H33c.5 0 .9-.1 1.2-.4.3-.3.4-.6.4-1.1.1-.5-.1-.9-.4-1.2zm-9.6 8.2c-.6.3-1.2.5-2 .5-1.2 0-2.1-.3-2.8-.9-.7-.6-1-1.5-1-2.6h2.7c0 .5.1.9.3 1.1.2.2.5.3.9.3s.7-.1.9-.4c.2-.3.3-.7.3-1.2v-7.6h2.7V27c0 .7-.2 1.3-.5 1.9-.5.6-.9 1-1.5 1.3zM12 32H2c-1.1 0-2-.9-2-2v-1.9c0-.4.3-.8.8-.8s.8.3.8.8V30c0 .3.2.5.5.5h10c.3 0 .5-.2.5-.5v-1.9c0-.4.3-.8.8-.8.4 0 .8.3.8.8V30c-.2 1.1-1.1 2-2.2 2zm-4.5-3.9-.1.1s-.1 0-.1.1c-.2.1-.4.1-.6 0 0 0-.1 0-.1-.1 0 0-.1 0-.1-.1L2.8 25c-.3-.3-.4-.8-.1-1.1.3-.3.8-.4 1.1-.1l2.5 2.1v-7.1c-.1-.5.3-.8.7-.8.4 0 .8.3.8.8v7.1l2.5-2.1c.1-.1.3-.2.5-.2s.4.1.6.3c.3.3.2.8-.1 1.1l-3.8 3.1z" />
			</symbol>
		</g>
	</svg>
</template>
<script>
    export default {
        name: 'Icons'
    }
</script>
<style lang="scss">
	#icons { display: none; }
</style>
