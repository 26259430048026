<template>
	<div v-if="linkid && countries" class="countries statpanel">
		<div class="statpanel__head">
			<span class="statpanel__title">
				<span class="statpanel__title__icon">
					<i class="pi pi-chart-line"></i>
				</span>
				<span class="statpanel__title__label">
					Countries
				</span>
			</span>
			<span class="statpanel__filters">
<!--				<Calendar placeholder="Select date range" v-model="value" selectionMode="range" dateFormat="dd.mm.yy" :maxDate="new Date()" />-->
				<Dropdown v-model="range" :options="rangeOpts" optionLabel="Intervallo" placeholder="Seleziona il range" :showClear="true">
					<template #value="slotProps">
						<div class="p-dropdown-icon-value" v-if="slotProps.value">
							{{slotProps.value.name}}
						</div>
						<span v-else>
									{{slotProps.placeholder}}
								</span>
					</template>
					<template #option="slotProps">
						<div class="p-dropdown-icon-option">
							{{slotProps.option.name}}
						</div>
					</template>
				</Dropdown>
			</span>
		</div>
		<span class="countries__list">
			<template v-if="chartLoading">
				<span class="statpanel__loading">
					<span class="statpanel__loading__spinner">
						<i class="pi pi-spinner"></i>
					</span>
				</span>
			</template>
			<span v-for="(r, rI) in countries" :key="rI" class="ref__item">
				<span class="ref__item__country">
					<span class="ref__item__country__flag">
						<img v-if="r.flag" :src="getFlagImage(r.flag)" alt="" />
					</span>
					<span class="ref__item__country__label">{{ r.label  }}</span>
				</span>
				<span class="ref__item__value">{{ r.count }}</span>
			</span>
		</span>
	</div>
</template>
<script>
import BarChart from '@/views/components/stats/BarChart';
import Calendar from 'primevue/calendar';
import Dropdown from "primevue/dropdown";

export default {
	name: 'OpeningOverTime',
	components: {
		Dropdown,
		BarChart,
		Calendar
	},
	props: {
		linkid: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			countries: null,
			value: null,
			from: '',
			to: '',
			chartLoading: false,
			range: null,
			rangeOpts: [
				{
					name: 'Last 7 days',
					value: 'last-7-days'
				},
				{
					name: 'Last 15 days',
					value: 'last-15-days'
				},
				{
					name: 'Last 30 days',
					value: 'last-30-days'
				},
				{
					name: 'Last 90 days',
					value: 'last-90-days'
				},
			]
		}
	},
	watch: {
		value: {
			handler(newval, oldval) {
				this.updateRange(newval);
				return newval;
			}
		}
	},
	methods: {
		getFlagImage(t) {
			if(t) {
				return `${require('@/assets/images/flags/' + t.toLowerCase() + '.svg')}`;
			} else {
				return '';
			}
		},
		updateRange(range) {
			this.chartLoading = false;
			if (range) {
				switch (range.value) {
					case 'last-7-days':
						this.from = new Date(new Date().setDate(new Date().getDate() - 7)).getTime();
						this.to = new Date().getTime();
						break;
					case 'last-15-days':
						this.from = new Date(new Date().setDate(new Date().getDate() - 15)).getTime();
						this.to = new Date().getTime();
						break;
					case 'last-30-days':
						this.from = new Date(new Date().setDate(new Date().getDate() - 30)).getTime();
						this.to = new Date().getTime();
						break;
					case 'last-90-days':
						this.from = new Date(new Date().setDate(new Date().getDate() - 90)).getTime();
						this.to = new Date().getTime();
						break;
				}
				
				if(this.from && this.to) {
					this.$service.stats.countries(this.linkid, `from=${this.from}&to=${this.to}`).then(res => {
						this.chartLoading = false;
						this.oot = res;
					});
				}
			} else {
				this.chartLoading = false;
				this.$service.stats.countries(this.linkid).then(res => {
					this.countries = res;
				});
			}
		},
		getKeyByValue(object, value) {
			return Object.keys(object).find(key => object[key] === value);
		},
		getFlagKey(countryLabel) {
			return this.getKeyByValue(this.$store.state.flags, countryLabel);
		},
		init() {
			let flags = this.$store.state.flags;
			this.$service.stats.countries(this.linkid).then(res => {
				this.countries = res;
				
				this.countries.forEach(country => {
					country.flag = this.getFlagKey(country.label).toLowerCase();
				})
			});
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.countries {
	@extend %statpanel;
	.p-inputtext {
		@extend %text-13;
		&:enabled:focus {
			border-color: $color-bittersweet !important;
			box-shadow: none !important;
		}
	}
	.p-calendar {
		width: 100%;
	}
	.statpanel {
		&__title {
			width: calc(100% - 200px);
		}
		&__filters {
			width: 200px;
		}
		
	}
	&__list {
		display: block;
		width: 100%;
		.ref__item {
			@extend %flexCenterBetween;
			width: 100%;
			padding: var(--thirdgutter);
			border-bottom: 1px dashed rgba($color-silver, 0.3);
			&:nth-child(even) {
				background-color: $color-antiflash;
			}
			&__country {
				@extend %flexCenterStart;
				&__flag {
					display: block;
					width: 20px;
					img {
						display: block;
						width: 100%;
					}
				}
				&__label {
					padding-left: 10px;
				}
			}
		}
	}
	.p-dropdown-item {
		width: 100%;
		justify-content: flex-start;
		text-align: left;
		padding-left: 0;
		.p-dropdown-icon-option {
			padding-left: 0;
			justify-content: flex-start;
			@extend %text-13;
		}
	}
}
</style>
