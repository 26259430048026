<template>
	<div v-if="cat" class="modal confirm-delete-category">
		<div class="modal__box">
			<div class="modal__content">
				<span class="confirm-delete-category__title">
					<strong>{{ cat.name }}</strong><br />
					sta per essere eliminata. Continuare?<br />
				</span>
				<span class="confirm-delete-category__text">
					Questa operazione non può essere annullata!
				</span>
			</div>
			<div class="modal__ctas">
				<span class="modal__btn btn noborder cancel" @click="cancelAction">
					Annulla
				</span>
				<span class="modal__btn btn filled delete" @click="confirmDelete">
					Elimina
				</span>
			</div>
		</div>
	</div>
</template>
<script>
import { EventBus } from "@/events_bus";

export default {
	name: 'ConfirmDeleteQr',
	data() {
		return {
			cat: null,
		}
	},
	methods: {
		cancelAction() {
			this.cat = null;
		},
		confirmDelete() {
			this.$service.cats.delete(this.cat.id).then(res => {
				this.loading = false;
				setTimeout(() => {
					EventBus.$emit('update-list');
				}, 50)
			});
		},
		init() {

		}
	},
	mounted() {
		EventBus.$on('confirm-delete-category', item => {
			this.cat = item;
		})
	}
}
</script>

<style lang="scss">
.modal {
	@extend %modal;
	&__content {
		.confirm-delete-category {
			&__title,
			&__text {
				display: block;
				width: 100%;
			}
			&__title {
				@extend %text-20;
				color: $color-cerise;
				font-weight: 500;
				margin-bottom: var(--minigutter);

				strong {
					@extend %text-25;
					font-weight: 900;
				}
			}
			&__text {
				@extend %text-15;
				margin-top: var(--gutter);
				margin-bottom: var(--supergutter);
			}
		}
	}
}
</style>
