<template>
	<div id="app">
		<Icons />
		<router-view/>
		<a href="https://mwd.digital" target="_blank" class="credits">
			<svg viewBox="0 0 50 50">
				<use xlink:href="#logo--mwd" width="100%" height="100%"></use>
			</svg>
		</a>
	</div>
</template>
<script>
import { EventBus } from '@/events_bus.js';

import Icons from '@/views/layouts/utils/Icons';

export default {
	name: 'App',
	components: {
		Icons,
	},
	data() {
		return {
			windowWidth: 0,
		}
	},
	methods: {
		onResize() {
			let mode = localStorage.getItem('mode');
			this.windowWidth = window.innerWidth;
			if(this.windowWidth < 1200) {
				localStorage.setItem('mode', 'mobile');
				if(mode != 'mobile') {
					window.location.reload();
				}
			} else {
				localStorage.setItem('mode', 'desktop');
				if(mode != 'desktop') {
					window.location.reload();
				}
			}
		},
		init() {
		
		},
	},
	mounted() {
		this.init();
		this.onResize();
		this.$nextTick(() => {
			window.addEventListener('resize', () => {
				this.onResize();
			});
		});

	}
}
</script>
<style lang="scss">
#app {
	display: block;
	background: $color-antiflash;
	width: 100%;
	min-height: 100svh;
	position: relative;
	.credits {
		position: fixed;
		bottom: 20px;
		right: 20px;
		@include size(var(--gutter),var(--gutter));
		z-index: 100;
		&:before {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			@include size(120%,120%);
			background: linear-gradient(45deg, $color-violet, $color-ultraviolet 100%);
			transform: translate(-50%,-50%) scale(0);
			border-radius: 100%;
			transition: 359ms var(--ease-inout-sine);
			z-index: 0;
		}
		svg {
			display: block;
			@include size(100%,100%);
			fill: $color-cerise;
			position: relative;
			z-index: 2;
			transition: 180ms linear;
		}
		&:hover {
			&:before {
				transform: translate(-50%,-50%) scale(1);
				box-shadow: 0 0 10px rgba($color-gun, 0.5);
			}
			svg {
				fill: $color-white;
			}
		}
	}
}
</style>
