<template>
	<div v-if="info" class="totalopenings statpanel">
		<span class="statpanel__title">
			<span class="statpanel__title__icon">
				<i class="pi pi-chart-line"></i>
			</span>
			<span class="statpanel__title__label">
				Total Openings
			</span>
		</span>
		<ICountUp class="totalopenings__value" :endVal="info.total" :options="{duration: 2, separator: ','}" />
	</div>
</template>
<script>
import ICountUp from 'vue-countup-v2';

export default {
	name: 'TotalOpenings',
	props: {
		info: {
			type: Object,
			required: true,
		}
	},
	components: {
		ICountUp
	},
	data() {
		return {
		}
	},
	methods: {
		init() {
			console.log('Total Openings: ', this.info);
		}
	},
	mounted() {
		this.init();
	}
}
</script>
<style lang="scss">
.totalopenings {
	@extend %statpanel;
	@extend %flexCenterBetween;
	display: flex;
	.statpanel__title {
		width: 40%;
		margin-bottom: 0;
	}
	.statpanel__value {
		border-radius: 100%;
		border: 2px dotted $color-silver;
	}
	&__value {
		@extend %text-33;
		display: block;
		width: 60%;
		font-weight: 700;
		color: $color-bittersweet;
		text-align: right;
	}
}
</style>
