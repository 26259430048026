export default {
	install: function (Vue) {
		let service = {};
		Vue.mixin({
			mounted() {
				/**
				 * Crea i parametri per la lista
				 * @param page
				 * @param sort
				 * @param search
				 * @param search_fields
				 * @returns {string}
				 */
				const buildQueryParams = (params) => {
					let paramData = [];
					if (params) {
						Object.keys(params).map((key) => {
							paramData.push(
								`${key}=${encodeURIComponent(params[key])}`
							);
						});
						return paramData.length ? params.join("&") : ``;
					}
					return ``;
				};

				/**
				 * LOGIN
				 */
				let auth = {};
				auth.signin = (obj) => {
					return this.$api.post(`/auth/signin`, {
						email: obj.email,
						password: obj.password,
					});
				}
				auth.signup = (obj) => {
					return this.$api.post(`/auth/signup`, {
						email: obj.email,
						password: obj.password,
						confirm: obj.confirm
					})
				}
				auth.forgotPassword = (email) => {
					return this.$api.post(`/auth/forgot-password`, {
						email: email
					})
				}
				auth.resetPassword = (obj) => {
					return this.$api.post(`/auth/reset-password`, {
						token: obj.token,
						email: obj.email,
						password: obj.password,
						confirm: obj.confirm
					})
				}
				auth.getUser = () => {
					return this.$api.get(`/auth/me`);
				}
				auth.refreshToken = () => {
					return this.$api.post(`/auth/refresh`);
				}

				service.auth = auth;

				let link = {}
				link.get = (opts) => {
					let params = `?${opts}` || {};
					return this.$api.get(`/links${params}`);
				}
				link.getSingle = (linkid) => {
					return this.$api.get(`/links/${linkid}`)
				}
				link.create = (link) => {
					return this.$api.post(`/links`, link)
				}
				link.edit = (linkid, item) => {
					return this.$api.put(`/links/${linkid}`, item);
				}
				link.delete = (linkid) => {
					return this.$api.delete(`/links/${linkid}`);
				}

				service.link = link;

				let cats =  {};
				cats.get = (opts) => {
					let params = `?${opts}` || {};
					return this.$api.get(`/categories${params}`);
				}
				cats.getSingle = (catid) => {
					return this.$api.get(`/categories/${catid}`)
				}
				cats.create = (cat) => {
					return this.$api.post(`/categories`, cat);
				}
				cats.update = (catid, cat) => {
					return this.$api.put(`/categories/${catid}`, cat);
				}
				cats.delete = (catid) => {
					return this.$api.delete(`/categories/${catid}`);
				}

				service.cats = cats;

				let stats = {};
				stats.totalOpening = (linkid) => {
					return this.$api.get(`/links/${linkid}/stats/total`);
				}
				stats.openCountOverTime = (linkid, params) => {
					let p = params ? `?${params}` : '';
					return this.$api.get(`/links/${linkid}/stats/histogram${p}`);
				}
				stats.referers = (linkid, params) => {
					let p = params ? `?${params}` : '';
					return this.$api.get(`/links/${linkid}/stats/referers`);
				}
				stats.countries = (linkid, params) => {
					let p = params ? `?${params}` : '';
					return this.$api.get(`/links/${linkid}/stats/countries`);
				}
				stats.totalOpeningDash = () => {
					return this.$api.get(`/stats/total`);
				}
				stats.openCountOverTimeDash = (params) => {
					let p = params ? `?${params}` : '';
					return this.$api.get(`/stats/histogram${p}`);
				}
				stats.referersDash = (params) => {
					let p = params ? `?${params}` : '';
					return this.$api.get(`/stats/referers${p}`);
				}
				stats.countriesDash = (params) => {
					let p = params ? `?${params}` : '';
					return this.$api.get(`/stats/countries${p}`);
				}

				service.stats = stats;

			},
		});
		Vue.prototype.$service = service;
	},
};
