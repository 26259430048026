<template>
	<div v-if="isloading" class="page__loading">
		<div class="page__loading__box">
			<span class="page__loading__spinner">
				<i class="pi pi-spinner"></i>
			</span>
			<span class="page__loading__label">
				Loading...
			</span>
		</div>
	</div>
</template>
<script>
import LoginBackground from "@/views/components/login/LoginBackground.vue";

export default {
	name: 'PageLoading',
	components: {LoginBackground},
	props: {
		isloading: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
		
		}
	},
	mounted() {
		if(this.isloading) {
			document.querySelector('.page__loading').classList.add('show');
		}
	}
};
</script>
<style lang="scss">
.page__loading {
	position: fixed;
	top: 0;
	left: 0;
	@extend %flexCenterCenter;
	background: rgba($color-white, 0.9);
	@include size(100%,100svh);
	z-index: 10000;
	opacity: 0;
	pointer-events: none;
	transition: 180ms linear;
	&__box {
		display: block;
		width: 250px;
	}
	&__spinner {
		display: block;
		font-size: 2.5rem;
		color: $color-cerise;
		animation: spin 2s linear infinite;
		transform: translateY(100vh);
		transition-delay: 100ms;
	}
	&__label {
		display: block;
		text-align: center;
		font-size: 0.8rem;
		color: $color-cerise;
		text-transform: uppercase;
		margin-top: var(--minigutter);
		transform: translateY(100vh);
		transition-delay: 200ms;
	}
	@keyframes spin {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
	&.show {
		pointer-events: all;
		opacity: 1;
		.page__loading__spinner,
		.page__loading__label {
			transform: translateY(0);
		}
	}
}
</style>
